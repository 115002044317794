import React from 'react';


export interface TextAreaBoxProps {
    label: string;
    name: string;
    value?: string;
    messagePlaceholder?: string;
    isInEditMode?: boolean;
    isSubmitted?: boolean;
    errorText?: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextAreaBox = ({
                                label,
                                name,
                                value,
                                isInEditMode = true,
                                isSubmitted = false,
                                errorText,
                                messagePlaceholder,
                                onChange,
                                ...props
                            }: TextAreaBoxProps) => {

    const inputClassBorderColor = errorText?.length && errorText?.length > 0 ? " border-red-600 " :
        isSubmitted ? " border-green-600 " : " border-gray-300 "
    const inputClassBorder =
        "focus:border-b-2 appearance-none border-b "
    const inputClassNameString = "block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b " +
        "border-gray-300 appearance-none focus:outline-none focus:ring-0 peer" +
        (isInEditMode ? inputClassBorder : "border-transparent ") + inputClassBorderColor

    const labelClassNameString = "block mb-0 text-sm font-normal text-gray-500 text-summer-green-600 "

    return (<>
            <label htmlFor="message"
                   className={labelClassNameString}>{label}</label>
            <textarea id="message"
                      name={name}
                      rows={7}
                      className={inputClassNameString}
                      value={value}
                      onChange={onChange}
                      placeholder={messagePlaceholder}/>
            <p className={"text-red-600 text-sm mt-2"}>{errorText}</p>
        </>
    );
}
