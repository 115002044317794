import * as React from "react"
import {useEffect} from "react"
import {Header} from "../components/Header";
import {WhatsAppIcon} from "../icons/WhatsAppIcon";
import {ChevronUpIcon} from "../icons/ChevronUpIcon";
import {ChevronDownIcon} from "../icons/ChevronDownIcon";
import {MailIcon} from "../icons/MailIcon";
import {IconButton} from "../components/IconButton";
import {LocationIcon} from "../icons/LocationIcon";
import {PhoneIcon} from "../icons/PhoneIcon";
import {Card} from "../components/Card";
import {StaticImage} from "gatsby-plugin-image";
import {EmailWebForm} from "../components/EmailWebForm";
import {EmailClientCard} from "../components/EmailClientCard";
import {Layout} from "../components/Layout";
import {PhoneCard} from "../components/PhoneCard";

export interface LocationProps {
    key: string;
    pathname: string;
    search: string;
}

export interface ContactPageProps {
    location: LocationProps;
}

export default function ContactPage({location}: ContactPageProps) {

    const [accordionVisibility, setAccordionVisibility] = React.useState([true, true, true, true]);

    const contactParam = location.search.split('contact=')[1];

    useEffect(() => {
        if (contactParam === "whatsapp") {
            toggleAccordionVisibility(0)
        } else if ((contactParam === "mail")) {
            toggleAccordionVisibility(1)
        } else if ((contactParam === "telefoon")) {
            toggleAccordionVisibility(2)
        } else if ((contactParam === "locatie")) {
            toggleAccordionVisibility(3)
        }
    }, [contactParam])

    const replaceAt = (index: number, array: boolean[]) => {
        const ret = array.slice(0);
        ret[index] = !array[index];
        return ret;
    }
    const toggleAccordionVisibility = (index: number) => {
        setAccordionVisibility(replaceAt(index, [false, false, false, false]))
        const id = `accordion-collapse-heading-${index}`
        console.log(id)
        if (document.getElementById(id)) {
            // @ts-ignore
            document.getElementById(id).scrollIntoView();
        }
    }

    return (
        <Layout contactWidget={false}
                pageTitle={"Neem nu contact | Steven's bike service"}
                metaDescription={"Neem nu contact op met Steven's bike service! Dé MTB specialist van regio Deventer! ✓ MTB onderhoud ✓ MTB reparatie ✓ Jarenlange ervaring"}>

            <h1 className={"text-3xl sm:text-4xl md:text-5xl font-bold mt-24 mb-10"}>De contact pagina</h1>
            <p className={"text-xl mb-10"}>
                Hier kun je contact leggen via <span
                className={"cursor-pointer font-bold text-blue-700 hover:underline"}
                onClick={() => toggleAccordionVisibility(0)}>WhatsApp</span>, <span
                className={"cursor-pointer font-bold text-blue-700 hover:underline"}
                onClick={() => toggleAccordionVisibility(1)}>mail</span>, <span
                className={"cursor-pointer font-bold text-blue-700 hover:underline"}
                onClick={() => toggleAccordionVisibility(2)}>telefoon</span> of vinden waar je me
                kunt bezoeken op <span className={"cursor-pointer font-bold text-blue-700 hover:underline"}
                                       onClick={() => toggleAccordionVisibility(3)}>locatie</span>.
            </p>

            <div id="accordion-collapse" data-accordion="collapse">
                <h2>
                    <div id="accordion-collapse-heading-3" onClick={() => toggleAccordionVisibility(3)}
                         className="flex justify-between items-center p-5 w-full font-medium focus:font-bold text-left text-gray-500 border border-gray-200 focus:bg-gray-100 :ring-gray-800  hover:bg-gray-100 :bg-gray-800"
                         data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                         aria-controls="accordion-collapse-body-3">
                        <IconButton
                            size={"lg"}
                            isPrimary
                            ariaLabel={"Op lokatie"}>
                            <LocationIcon/>
                        </IconButton>
                        <span>In de werkplaats</span>
                        {accordionVisibility[3] ? <ChevronUpIcon/> : <ChevronDownIcon/>}
                    </div>
                </h2>
                <div id="accordion-collapse-body-3"
                     className={accordionVisibility[3] ? "" : "hidden"}
                     aria-labelledby="accordion-collapse-heading-3">
                    <div className="w-full grid gap-10 md:grid-cols-2 p-5 border border-b-0 border-gray-200 ">
                        <Card>
                            <h3 className="my-5 px-4 text-2xl font-bold text-center tracking-tight text-gray-900 ">
                                Ik ben alleen open <br/>op afspraak
                            </h3>
                            <div className="w-full grid gap-2 p-5">
                                <StaticImage src={"../images/sbs-contact.jpg"} alt={"Contact werkplaats"}
                                             className={"mx-auto my-1 rounded-lg shadow-xl "}/>
                                {/*<p className="mb-3 px-10 text-center lg:text-xl text-gray-700 font-bold">*/}
                                {/*  Ik ben alleen open op afspraak*/}
                                {/*</p>*/}
                            </div>
                            <p className="mb-3 px-10 text-center lg:text-xl font-normal text-gray-700 ">
                                <span className={"font-bold"}>Steven's bike service</span><br/>
                                Steven van Vliet<br/>
                                van der Waalsstraat 35<br/>
                                7424 GW&nbsp;&nbsp;Deventer<br/>
                                telefoonnummer: <a href={"tel: 0612712347"}
                                                   className={"text-blue-600 hover:underline"}> 0612712347</a><br/>
                                mail: <a
                                href={"mailto:info@stevensbikeservice.nl?subject=Mail van de stevenbikeservice.nl website"}
                                className={"text-blue-600 hover:underline"}
                                target={"_blank"}>info@stevensbikeservice.nl</a>
                            </p>
                        </Card>
                        <Card>
                            <h3 className="my-5 px-4 text-2xl font-bold text-center tracking-tight text-gray-900 ">
                                Route plannen met Google Maps
                            </h3>
                            <div className="m-7">
                                <div className="h-96 w-full mx-auto">
                                    <iframe className={"h-96 w-full"}
                                            title="Google maps kaart"
                                            id="gmap_canvas"
                                            src="https://maps.google.com/maps?q=van%20der%20waalsstraat%2035%20deventer&t=&z=15&ie=UTF8&iwloc=&output=embed"/>
                                </div>
                            </div>
                            <div className={"mb-auto"}>
                                &nbsp;
                            </div>
                        </Card>
                    </div>
                </div>
                <h2>
                    <div id="accordion-collapse-heading-0" onClick={() => toggleAccordionVisibility(0)}
                         className="flex justify-between items-center p-5 w-full font-medium text-left rounded-t-lg border border-b-0 border-gray-200 focus:border-0 focus:bg-gray-100 hover:bg-gray-100 text-gray-900"
                         data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                         aria-controls="accordion-collapse-body-1">
                        <IconButton
                            size={"lg"}
                            isPrimary
                            ariaLabel={"WhatsApp"}
                            primaryColor={"green"}>
                            <WhatsAppIcon/>
                        </IconButton>
                        <span>Via WhatsApp</span>
                        {accordionVisibility[0] ? <ChevronUpIcon/> : <ChevronDownIcon/>}
                    </div>
                </h2>
                <div id="accordion-collapse-body-0"
                     className={accordionVisibility[0] ? "" : "hidden"}
                     aria-labelledby="accordion-collapse-heading-1">
                    <div className="w-full grid gap-10 md:grid-cols-2 p-5 border border-b-0 border-gray-200 ">
                        <Card>
                            <a href={"https://wa.me/31612712347"} target={"_blank"}>
                                <div className={"mx-auto my-10 w-24 md:w-40 lg:w-52"}>
                                    <StaticImage src={"../images/sbs-whatsapp.jpg"} alt={"WhatsApp logo"}/>
                                </div>
                                <h3 className="mb-2 text-2xl font-bold text-center tracking-tight text-gray-900 ">
                                    WhatsApp openen
                                </h3>
                                <p className="mb-3 px-10 text-center font-normal text-gray-700 ">Hiermee
                                    open je WhatsApp in een chat met Steven's bike service.
                                </p>
                            </a>
                        </Card>
                        <Card>
                            <StaticImage src={"../images/sbs-whatsapp-qr.jpg"} alt={"WhatsApp QR code"}
                                         className={"mx-auto my-10 shadow-xl w-24 md:w-40 lg:w-52"}/>
                            <h3 className="mb-2 text-2xl font-bold text-center tracking-tight text-gray-900 ">
                                QR code scannen met de telefoon
                            </h3>
                            <p className="mb-3 px-10 text-center font-normal text-gray-700 ">
                                Hiermee voeg je Steven's bike service toe als contact in WhatsApp en kun je meteen gaan
                                app-en.
                            </p>
                            <p className="mb-3 px-10 text-center font-normal text-gray-700 ">
                                Open WhatsApp, ga naar Instellingen en klik op de qr code, rechtsbovenin.
                            </p>
                        </Card>
                    </div>
                </div>
                <h2>
                    <div id="accordion-collapse-heading-1" onClick={() => toggleAccordionVisibility(1)}
                         className="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:bg-gray-100 hover:bg-gray-100"
                         data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                         aria-controls="accordion-collapse-body-2">
                        <IconButton
                            size={"lg"}
                            isPrimary
                            ariaLabel={"eMail"}>
                            <MailIcon/>
                        </IconButton>
                        <span>Via de email</span>
                        {accordionVisibility[1] ? <ChevronUpIcon/> : <ChevronDownIcon/>}
                    </div>
                </h2>
                <div id="accordion-collapse-body-1"
                     className={accordionVisibility[1] ? "" : "hidden"}
                     aria-labelledby="accordion-collapse-heading-2">
                    <div className="w-full grid gap-10 md:grid-cols-2 p-5 border border-b-0 border-gray-200 ">
                        <EmailWebForm/>
                        <EmailClientCard/>
                    </div>
                </div>
                <h2>
                    <div id="accordion-collapse-heading-2" onClick={() => toggleAccordionVisibility(2)}
                         className="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-gray-200 focus:bg-gray-100 :ring-gray-800  hover:bg-gray-100 :bg-gray-800"
                         data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                         aria-controls="accordion-collapse-body-3">
                        <IconButton
                            size={"lg"}
                            isPrimary
                            ariaLabel={"Telefoon"}>
                            <PhoneIcon/>
                        </IconButton>
                        <span>Via de telefoon</span>
                        {accordionVisibility[2] ? <ChevronUpIcon/> : <ChevronDownIcon/>}
                    </div>
                </h2>
                <div id="accordion-collapse-body-2"
                     className={accordionVisibility[2] ? "" : "hidden"}
                     aria-labelledby="accordion-collapse-heading-2">
                    <div className="w-full grid gap-10 md:grid-cols-2 p-5 border border-b-0 border-gray-200 ">
                        <Card>
                            <h3 id={"accordion-collapse-card-2"}
                                className="my-5 text-2xl font-bold text-center tracking-tight text-gray-900 ">
                                vCard QR code scannen met de telefoon
                            </h3>
                            <StaticImage src={"../images/sbs-vcard-qr.jpg"} alt={"Contact QR code"}
                                         className={"mx-auto my-2 w-24 md:w-40 lg:w-52"}/>
                            <p className="mb-3 px-10 text-center font-normal text-gray-700 ">
                                Hiermee voeg je Steven's bike service toe als contact in je telefoon en heb je alle
                                contact gegevens.
                            </p>
                            <p className="mb-3 px-10 text-center font-normal text-gray-700 ">
                                Scan met de camera van je telefoon de code en klik op qr-code.
                            </p>
                        </Card>
                        <PhoneCard/>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
