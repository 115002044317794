import React, {ChangeEvent, useState} from 'react';
import {TextField} from "./TextField";
import {TextAreaBox} from "./TextAreaBox";
import {Card} from "./Card";
import {StaticImage} from "gatsby-plugin-image";

export interface IFetchProps {
    url: string;
    data: IContactFormFields;
}

export interface IContactFormFields {
    name?: string;
    email?: string;
    phone?: string;
    message?: string;
}

type FormSubmitSucces = "unsubmitted" | "failed" | "success"

export const EmailWebForm: React.FC = ({}) => {

    const initialFormFields = {
        name: "",
        email: "",
        phone: "",
        message: ""
    }

    const [formFields, setFormFields] = useState<IContactFormFields>(initialFormFields);
    const [formErrors, setFormErrors] = useState<IContactFormFields>({});
    const [isValidated, setIsValidated] = useState(false);
    const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState<FormSubmitSucces>("unsubmitted");

    const fetchPostUrl = async ({url, data}: IFetchProps): Promise<Response> => {
        return await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {'Content-Type': 'application/json'}
        })
    }

    const onSubmitForm = () => {
        const errors = validate(formFields);
        setFormErrors(errors);
        setIsValidated(true);
        if (Object.keys(errors).length === 0) {
            fetchPostUrl({url: "/api/contact.php", data: formFields})
                .then(
                    (response: Response) => {
                        response.text()
                            .then(body => {
                                setIsFormSubmitSuccess(body as FormSubmitSucces)
                                const element = document.getElementById("accordion-collapse-heading-2")
                                // const element = document.getElementById(body)
                                if (element) element.scrollIntoView();
                            })
                    },
                    error => {
                        setIsFormSubmitSuccess("failed")
                    });
        }
    }

    const validate = (values: IContactFormFields) => {
        const errors: IContactFormFields = {};
        const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const phoneRegex = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/i
        if (!values.name) {
            errors.name = "Naam is verplicht.";
        }
        if (!values.email && !values.phone) {
            errors.email = "Of een email, óf een telefoonnummer invullen.";
            errors.phone = " ";
        } else if (values.email && !mailRegex.test(values.email)) {
            errors.email = "Dit is geen geldige email.";
        }
        if (values.phone && !phoneRegex.test(values.phone.replace(/[ \-\.\(\)]/g, ''))) {
            errors.phone = "Dit is geen geldig telefoonnummer.";
        }
        if (!values.message) {
            errors.message = "Zonder bericht heeft mailen niet veel zin, toch?";
        } else if (values.message.length > 500) {
            errors.message = "Het bericht graag beperken tot 500 tekens.";
        }
        return errors;
    };

    const onChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormFields({...formFields, [name]: value});
    };

    return (
        <> {
            (isFormSubmitSuccess === "unsubmitted") &&
            <Card>
                <h5 className="my-5 text-2xl font-bold text-center tracking-tight text-gray-900 ">
                    Mail versturen via dit webformulier
                </h5>
                <div className="p-5">
                    <div className="w-full flex flex-row justify-between flex-wrap">
                        <div className={"w-full "}>
                            <div className=" flex flex-row justify-between flex-wrap">
                                <div className={"w-full"}>
                                    <TextField label={"Naam"}
                                               name={"name"}
                                               isSubmitted={isValidated}
                                               errorText={formErrors.name}
                                               value={formFields.name}
                                               onChange={onChangeInput}/>
                                </div>
                            </div>
                            <div className=" flex flex-row justify-between flex-wrap">
                                <div className={"w-full"}>
                                    <TextField label={"Email adres"}
                                               isSubmitted={isValidated}
                                               type={"email"}
                                               errorText={formErrors.email}
                                               name={"email"}
                                               value={formFields ? formFields.email : ""}
                                               onChange={onChangeInput}/>
                                </div>
                            </div>
                            <div className=" flex flex-row justify-between flex-wrap">
                                <div className={"w-full"}>
                                    <TextField label={"Telefoon"}
                                               isSubmitted={isValidated}
                                               errorText={formErrors.phone}
                                               name={"phone"}
                                               value={formFields ? formFields.phone : ""}
                                               onChange={onChangeInput}/>
                                </div>
                            </div>
                            <TextAreaBox label={"Je vraag of opmerking"}
                                         isSubmitted={isValidated}
                                         errorText={formErrors.message}
                                         name={"message"}
                                         value={formFields ? formFields.message : ""}
                                         onChange={onChangeInput}/>
                            <button onClick={onSubmitForm} aria-label={"Versturen"}
                                    className="mt-5 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center :bg-blue-700 :ring-blue-800">
                                Versturen
                            </button>
                        </div>
                    </div>
                </div>
            </Card>
        } {
            (isFormSubmitSuccess === "success") &&
            <Card>
          <span id={"success"}>
            <h5 className="my-5 text-2xl font-bold text-center tracking-tight text-gray-900 ">
              Bedankt voor je bericht
            </h5>
            <div className={"mx-auto my-10 w-24 md:w-40 lg:w-52"}>
              <StaticImage src={"../images/sbs-party.jpg"} alt={"Verzenden gelukt"}/>
            </div>
            <p className="mb-3 px-10 text-center font-normal text-gray-700 ">
              We nemen binnen een werkdag contact met je op.
            </p>
            </span>
            </Card>
        } {
            (isFormSubmitSuccess !== "unsubmitted" && isFormSubmitSuccess !== "success") &&
            <Card>
          <span id={"failed"}>
            <h5 className="my-5 text-2xl font-bold text-center tracking-tight text-gray-900 ">
              Sorry, verzenden is NIET gelukt ...
            </h5>
            <div className={"mx-auto my-10 w-24 md:w-40 lg:w-52"}>
              <StaticImage src={"../images/sbs-failed.jpg"} alt={"Verzenden mislukt"}/>
            </div>
            <p className="mb-3 px-10 text-center font-normal text-gray-700 ">
              Het is onduidelijk wat er mis ging. Probeer langs een van de andere kanalen contact op te nemen.
            </p>
            </span>
            </Card>
        }
        </>
    )
};
