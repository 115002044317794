import React from 'react';
import {StaticImage} from "gatsby-plugin-image"
import {Card} from "./Card";

interface EmailClietCardProps {
}

export const EmailClientCard: React.FC<EmailClietCardProps> = ({}) => (


    <Card>
        <a href={"mailto:info@stevensbikeservice.nl?subject=Mail van de stevenbikeservice.nl website"}
           target={"_blank"}>
            <h5 className="my-5 text-2xl font-bold text-center tracking-tight text-gray-900 ">
                Mail versturen via je eigen mail client
            </h5>
            <div className={"mx-auto my-10 w-24 md:w-40 lg:w-52"}>
                <StaticImage src={"../images/sbs-mail.jpg"} alt={"Mail logo"}/>
            </div>
            <p className="mb-3 px-10 text-center font-normal text-gray-700 ">
                Hiermee open je je mail programma met 'Aan' en het 'Onderwerp' al ingevuld.
            </p>
        </a>
    </Card>
);
