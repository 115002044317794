import React from 'react';
import {StaticImage} from "gatsby-plugin-image"
import {Card} from "./Card";

interface PhoneCardProps {
}

export const PhoneCard: React.FC<PhoneCardProps> = ({}) => (


    <Card>
        <a href={"tel: 0612712347"}>
            <h5 className="my-5 text-2xl font-bold text-center tracking-tight text-gray-900 ">
                Direct bellen
            </h5>
            <div className={"mx-auto my-10 w-24 md:w-40 lg:w-52"}>
                <StaticImage src={"../images/sbs-phone.jpg"} alt={"Phone logo"}/>
            </div>
            <p className="mb-3 px-10 text-center font-normal text-gray-700 ">
                Dit werkt doorgaans alleen op je telefoon, tenzij je een programma hebt waarmee
                je kan bellen op het aparaat waarmee je deze website bekijkt ...
            </p>
        </a>
    </Card>
);
